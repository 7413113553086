<template>
  <div :class="`nav-lv-${index}`">
    <div
      v-if="hasCloseMenuEvent"
      role="button"
      class="back-button flex flex-row items-center h-12 w-full bg-gray-800 text-white cursor-pointer"
      @click.stop="closeLoginMenu"
    >
      <icon name="IconChevronLeft" /> <span class="flex-1">Back</span>
    </div>
    <GlobalNavBackButton v-else class="md:hidden" />
    <ul v-for="(columns, i) in columnCount" :key="i">
      <li
        v-for="(item, j) in columns"
        :key="item.index"
        :class="{ active: j === activeChild }"
      >
        <GlobalNavListItem :item="item" :index="j" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'GlobalNavbarList',
  props: {
    items: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    split: {
      type: Number,
      // larger number than ever needed as default
      default: 96
    },
    activeChild: {
      type: Number,
      default: null
    },
    onCloseMenuEvent: {
      type: Function,
      default: null
    }
  },
  emits: ['closeMenuEvent'],
  computed: {
    columnCount() {
      return this.items.reduce((all, one, i) => {
        const ch = Math.floor(i / this.split)
        all[ch] = [].concat(all[ch] || [], one)
        return all
      }, [])
    },
    hasCloseMenuEvent() {
      return !!this.onCloseMenuEvent
    }
  },
  methods: {
    closeLoginMenu() {
      this.$emit('closeMenuEvent')
    }
  }
}
</script>

<style lang="postcss" scoped>
@media (min-width: 768px) {
  .mega-menu .nav-lv-2 ul {
    @apply border-r border-gray-300;
  }
  .mega-menu .nav-lv-2 ul + ul:last-of-type {
    @apply border-0;
  }
}
.back-button .tf-icon {
  @apply text-2xl mx-2;
}
</style>
