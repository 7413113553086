<template>
  <div class="global-nav-search">
    <SearchBar
      id="headerSearch"
      v-model:search-param="keyword"
      class="my-auto"
      button-text="Search courses"
      placeholder-text="Search for courses or other content"
      label-text="Search for courses or other content"
      @search="
        globalNavStore.closeNav(),
          $router.push({ name: 'course-search', query: { keyword } })
      "
    />
  </div>
</template>

<script setup lang="ts">
import { useGlobalNavStore } from '~/stores/globalNav'
const globalNavStore = useGlobalNavStore()

const keyword = ref('')
</script>

<style lang="postcss">
/*
  while we need to maintain visual parity between the E2E global navigation and Liferay global navigation we
  need to make use of a lot of custom css classes, once we no longer have need for the Liferay global
  navigation parity, we can remove as many custom classes as possible and move the Tailwind CSS classes
  into the template
*/

.global-nav-search {
  @apply flex flex-col;
}
#headerSearch {
  background: transparent;
  border-color: transparent;
}

@media (max-width: 767px) {
  #headerSearch {
    @apply bg-gray-700;
  }
  #headerSearch,
  #headerSearch + label .tf-icon,
  #headerSearch + label + button .tf-icon {
    @apply text-white;
  }
}

@media (min-width: 768px) {
  .global-nav-search {
    @apply bg-transparent ml-auto;
  }

  #headerSearch {
    width: 3rem;
    padding-left: 0;
    padding-right: 0;
    text-indent: 100px;
    /* TODO: tweak transitions esp. bg-color */
    transition: all 0.36s;
    color: transparent;
  }

  #headerSearch:focus {
    background: white;
    color: black;
    border-color: #3182ce;
    padding-left: 3rem;
    width: 40vw;
    text-indent: 0;
  }

  #headerSearch + label {
    color: white;
  }

  #headerSearch:focus + label {
    color: black;
  }

  #headerSearch + label + button {
    display: none;
  }

  #headerSearch:focus + label + button {
    display: block !important;
  }
}

@media (min-width: 2561px) {
  #headerSearch:focus {
    background: white;
    color: black;
    border-color: #3182ce;
    padding-left: 3rem;
    width: 20vw;
    text-indent: 0;
  }
}
</style>
