<template>
  <ul
    v-click-outside="$viewport.isLessThan('lg') ? () => null : closeProfileMenu"
    class="global-nav-tools flex flex-col md:flex-row -mt-px"
  >
    <li :class="{ hidden: !displayChat }" class="md:hidden">
      <div class="global-nav-tool cursor-pointer" @click="openChat">
        <span class="flex-grow">Online Chat</span>
        <icon name="IconChat" class="ml-1 font-sm" />
      </div>
    </li>
    <li class="md:hidden">
      <a
        href="https://tafensw.custhelp.com/app/contact-us/enquire?campaign=e2e"
        target="_blank"
        rel="noopener noreferrer"
        class="global-nav-tool"
      >
        <span class="flex-grow">Ask a question</span>
        <icon name="IconHelp" class="ml-1" />
      </a>
    </li>
    <li class="md:hidden">
      <ClientOnly>
        <NuxtLink
          to="/shortlist"
          style="text-decoration: none; color: inherit"
          class="global-nav-tool"
          @click="gtag('event', 'shortlistClick')"
        >
          <span class="flex-grow">Shortlist</span>
          <div v-if="shortlistOfferings.length > 0" class="global-nav-pill">
            {{ shortlistOfferings.length }}
          </div>
        </NuxtLink>
      </ClientOnly>
    </li>
    <li v-if="isAuthenticated" class="md:hidden">
      <div class="global-nav-tool">
        <Icon name="IconVector" class="relative z-10 text-2xl -mr-1" />
        <span class="relative z-10">
          {{ getUserName('full') }}
        </span>
      </div>
    </li>
    <li v-if="isAuthenticated" class="md:hidden">
      <a
        :href="studentHub.href"
        style="text-decoration: none; color: inherit"
        class="global-nav-tool"
      >
        {{ studentHub.label }}
      </a>
    </li>
    <li
      v-if="!isAuthenticated"
      class="md:hidden"
      :class="{ active: showMobileLoginNav }"
    >
      <button
        class="nav-item w-full text-left lv-0"
        type="button"
        @click.stop="toggleMobileLoginNav()"
      >
        <span class="flex-1">Log in</span>
        <icon name="IconChevronRight" class="text-base" />
      </button>
      <div class="mega-menu md:shadow">
        <GlobalNavbarList
          :items="loginData.children"
          :index="1"
          class="log-in-nav nav-lv-1 bg-gray-700 text-white md:w-1/3 lg:w-1/4 md:py-4"
          :class="{ active: showMobileLoginNav }"
          :active-child="0"
          @close-menu-event="closeLoginMenu"
        />
      </div>
    </li>

    <li v-if="isAuthenticated" class="md:hidden">
      <NuxtLink
        to="/logout"
        style="text-decoration: none; color: inherit"
        class="global-nav-tool"
      >
        Log out
      </NuxtLink>
    </li>

    <li class="hidden md:block" :class="[showProfileMenu ? 'z-0' : 'z-10']">
      <ClientOnly>
        <NuxtLink
          to="/shortlist"
          style="text-decoration: none; color: inherit"
          class="global-nav-tool"
          @click="gtag('event', 'shortlistClick')"
        >
          <span class="flex-grow">Shortlist</span>
          <div v-if="shortlistOfferings.length > 0" class="global-nav-pill">
            {{ shortlistOfferings.length }}
          </div>
        </NuxtLink>
      </ClientOnly>
    </li>
    <li
      v-if="!isAuthenticated"
      class="ml-0 md:ml-auto hidden md:block relative"
    >
      <a
        role="button"
        class="pr-4 block relative z-10 global-nav-tool"
        href="#"
        @click="toggleProfileNav()"
      >
        <span class="relative z-10">
          {{ showProfileMenu ? headerData.close : headerData.login }}
        </span>
      </a>
      <div v-if="showProfileMenu" class="dropdown-menu-container">
        <Transition name="slide-fade">
          <ul v-if="showProfileMenu" class="dropdown-menu">
            <li class="w-full">
              <NuxtLink to="/student-hub" class="mb-3 block hover:underline">
                {{ headerData.studentLogin }}
              </NuxtLink>
            </li>
            <li class="w-full">
              <a :href="staffPortalUrl" class="mb-3 block hover:underline">
                {{ headerData.staffLogin }}
              </a>
            </li>
          </ul>
        </Transition>
      </div>
    </li>
    <li v-else class="ml-0 md:ml-auto relative hidden md:block">
      <a
        role="button"
        class="pr-4 block relative z-10 global-nav-tool logged-in-btn"
        href="#"
        @click.stop.prevent="toggleProfileNav"
      >
        <Icon name="IconVector" class="relative z-10 text-2xl -mr-1" />
        <span class="relative z-10">
          {{ getUserName('full') }}
        </span>
      </a>
      <div class="dropdown-menu-container">
        <Transition name="slide-fade">
          <ul v-if="showProfileMenu" class="dropdown-menu">
            <li>
              <a :href="studentHub.href" class="mb-3 block hover:underline">
                {{ studentHub.label }}
              </a>
            </li>
            <li>
              <NuxtLink to="/shortlist" class="mb-3 block hover:underline">
                {{ headerData.shortlist }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/logout"
                class="block hover:underline focus:underline"
              >
                {{ headerData.logout }}
              </NuxtLink>
            </li>
          </ul>
        </Transition>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useGlobalNavStore } from '~/stores/globalNav'
import { useUserStore } from '~/stores/user'
import { useUiStore } from '~/stores/ui'
import { useSiteConfigStore } from '~/stores/siteConfig'
import { useAuth } from '#auth/runtime/composables'

export default {
  name: 'ShowApplyServiceNavbar',
  props: {
    shortlistOfferings: {
      type: Array,
      default() {
        return []
      }
    }
  },
  async setup() {
    const config = useRuntimeConfig().public
    const { openMyChat } = useExternalWidget()
    const siteConfigStore = useSiteConfigStore()
    const $auth = useAuth()
    const { gtag } = useGtag()
    const uiStore = useUiStore()
    const staffPortalUrl = config.portalUrl
    const displayChat = computed(() => {
      if (siteConfigStore.config?.featureFlags?.displayChatIcon === null) {
        return uiStore.isChatOpen
      }
      return siteConfigStore.config?.featureFlags?.displayChatIcon
    })
    return {
      openMyChat,
      displayChat,
      staffPortalUrl,
      isAuthenticated: $auth.loggedIn,
      gtag
    }
  },
  data() {
    return {
      headerData: {
        login: 'Log in',
        studentLogin: 'Student login',
        staffLogin: 'Staff member login',
        close: 'Close',
        logout: 'Log out'
      },
      studentHub: {
        href: '/student-hub',
        label: 'Student hub'
      },
      showMobileLoginNav: false,
      loginData: {
        children: [
          {
            level: 1,
            title: 'Student login',
            to: '/student-hub'
          },
          {
            level: 1,
            title: 'Staff member login',
            href: this.staffPortalUrl
          }
        ],
        level: 0,
        title: 'Log in'
      }
    }
  },
  computed: {
    ...mapState(useGlobalNavStore, {
      showProfileMenu: 'profileMenuActive'
    }),
    ...mapState(useUserStore, ['getUserName'])
  },

  methods: {
    ...mapActions(useGlobalNavStore, [
      'setMobileNav',
      'toggleProfileNav',
      'closeNav'
    ]),
    openChat() {
      if (this.displayChat && this.openMyChat) {
        this.openMyChat()
      }
      this.setMobileNav(false)
    },
    closeProfileMenu() {
      this.toggleProfileNav(false)
    },
    toggleMobileLoginNav() {
      this.showMobileLoginNav = !this.showMobileLoginNav
    },
    closeLoginMenu() {
      this.showMobileLoginNav = false
    }
  }
}
</script>

<style lang="postcss" scoped>
.dropdown-menu-container {
  width: 272px;
  height: 193px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
}

.dropdown-menu {
  @apply absolute bg-gray-700 right-0 flex flex-col items-start justify-end p-4;
  width: 272px;
  top: -31px;
  height: 193px;
}

.logged-in-btn svg {
  height: 0.5em !important;
}

.global-nav-tool {
  @apply p-4 flex flex-row items-center;
}

.global-nav-tool:hover,
.global-nav-tool:focus {
  @apply bg-brand-grey-600;
}

@media (max-width: 767px) {
  /* Slide in nav levels */
  .nav-lv-1,
  .nav-lv-2 {
    @apply absolute inset-0 overflow-hidden z-50 transition-all duration-700;
    transform: translateX(100%);
  }
  .nav-lv-2 {
    background: #f4f4f5;
    color: #000;
  }
  .nav-lv-2 > ul {
    background: #f4f4f5;
  }
  .nav-lv-0 > li.active .nav-lv-1.active,
  .nav-lv-0 > li.active .nav-lv-2.mobile-active,
  .log-in-nav.active {
    @apply overflow-visible;
    transform: translateX(0);
  }
}

@media (min-width: 768px) {
  .mega-menu {
    @apply hidden;
    background: #fff;

    min-height: 150px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
  }

  li.active .mega-menu {
    @apply flex;
  }

  .nav-lv-2 {
    @apply hidden;
  }
  .nav-lv-2.active {
    @apply flex flex-1;
  }
  .nav-lv-2.active > ul {
    flex: 0 1 50%;
  }
}
</style>
