<template>
  <button
    type="button"
    class="relative w-6 h-6 cursor-pointer ml-auto mr-4 md:hidden"
    aria-label="Mobile menu button"
    :aria-expanded="active"
    @click.stop="toggleMobileNav"
  >
    <span class="sr-only">Open main menu</span>
    <span class="flex flex-col">
      <span
        class="nav-menu-button transform transition duration-500 ease-in-out"
        :class="[active ? 'rotate-45' : '-translate-y-2']"
      ></span>

      <span
        class="nav-menu-button transform transition duration-500 ease-in-out"
        :class="{ 'opacity-0': active }"
      ></span>

      <span
        class="nav-menu-button transform transition duration-500 ease-in-out"
        :class="[active ? '-rotate-45' : 'translate-y-2']"
      ></span>
    </span>
  </button>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalNavStore } from '~/stores/globalNav'

export default {
  name: 'GlobalNavMenuButton',
  computed: {
    ...mapState(useGlobalNavStore, {
      active: 'isMobileNavActive'
    })
  },
  methods: {
    ...mapActions(useGlobalNavStore, ['toggleMobileNav'])
  }
}
</script>

<style lang="postcss" scoped>
.nav-menu-button {
  @apply bg-white absolute w-full;
  height: 2px;
}
</style>
