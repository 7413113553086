<template>
  <div
    class="back-button flex flex-row items-center h-12 w-full bg-gray-800 text-white cursor-pointer"
    @click.stop="navigateUp()"
  >
    <icon name="IconChevronLeft" />
    <span class="flex-1">Back</span>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useGlobalNavStore } from '~/stores/globalNav'

export default {
  name: 'GlobalNavBackButton',
  methods: {
    ...mapActions(useGlobalNavStore, ['navigateUp'])
  }
}
</script>

<style lang="postcss" scoped>
.back-button .tf-icon {
  @apply text-2xl mx-2;
}
</style>
